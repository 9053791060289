
import Vue, { VueConstructor } from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"
import SelectSpace from "@/components/SelectSpace.vue"
import DateTimePicker from "@/components/DateTimePicker.vue"
import format from "date-fns/format"
import PositionsAutocompleteMixin from "@/mixins/PositionsAutocompleteMixin.vue"
import PermissionMixin from "@/mixins/PermissionMixin.vue"

interface Order {
  order_template_id: number
  space_id: number,
  position_id: number,
  started_at: string,
  finished_at: string,
  quantity: number,
  contract_type: string
  force_available_workers: boolean,
  force_all_periods: boolean,
  free_text: string
}

interface Period {
    id: number,
    started_at: {dateObject: Date | null, dateString: string, timeString: string},
    finished_at: {dateObject: Date | null, dateString: string, timeString: string}
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof PositionsAutocompleteMixin> & InstanceType<typeof PermissionMixin>>).extend({
  name: "OrderForm",
  mixins: [
    PositionsAutocompleteMixin,
    PermissionMixin
  ],
  components: {
    SelectSpace,
    DateTimePicker
  },
  data: () => ({
    quantity: 1,
    isLoading: {
      clients: false,
      positions: false
    },
    forceAvailableWorkers: false,
    forceAllPeriods: false,
    spaceFilter: true,
    freeText: "",
    periodList: [] as Period[],
    contractType: "",
    userPermittedContractTypes: [] as string[],
    contractTypes: ["temporary_work","permanent","temporary","individual_contractor_aggrements","subcontracting","training"]

  }),
  computed: {
    requiredRule() {
      return (v: any) => !!v || this.$vuetify.lang.t('$vuetify.required_field')
    },
    positiveNumberRule() {
      return (v: number) => ((v >= 1 ) || !v) || this.$vuetify.lang.t('$vuetify.positive_number_required')
    },
    maxLengthRule(){
      return (v: string) => ((v.length <= 2500)) || this.$vuetify.lang.t('$vuetify.invalid_length')
    }
  },
  watch: {
    contractType() {
      if (this.contractType === "permanent") {
        this.periodList.splice(1,this.periodList.length -1)
      }
    }
  },
  mounted() {
    this.addPeriod()
    this.contractTypes.forEach((type: string) => {
      if (this.hasPermission(`order_template_${type}`)) {
        this.userPermittedContractTypes.push(type)
      }
    });
    this.userPermittedContractTypes.push("training")
  },
  methods: {
    /**
     * translate contract type
     * @param type contract type to translate
     */
    getContractType(type: string) {
      return this.$vuetify.lang.t(`$vuetify.${type}`)
    },
    /**
     * Create an order with associated order periods
     */
    async createOrder() {
      // We extract only the needed data from the periodList
      let periodsBody = [] as {['started_at']: string, ['finished_at']: string}[]
      for (const period of this.periodList) {
        let finishedDate = `${period.finished_at.dateString} ${period.finished_at.timeString}:00`
        if (this.contractType === 'permanent') {
          let formatDateProcess = new Date(period.started_at.dateString)
          formatDateProcess.setFullYear(formatDateProcess.getFullYear()+1)
          finishedDate = `${format(formatDateProcess, "yyyy-MM-dd").toString()} ${period.started_at.timeString}:00`
        }
        periodsBody.push(
          {
            started_at: `${period.started_at.dateString} ${period.started_at.timeString}:00`,
            finished_at: finishedDate,
          }
        )
      }

      // Check if all periods follow a chronological order
      const periodErrors = this.checkChronology(periodsBody)
      if(periodErrors.length > 0) {
        EventBus.$emit('snackbar',{message:`${this.$vuetify.lang.t('$vuetify.period_chronology_error')}: ${periodErrors.join(", ")} `, color: "error"})
        return
      }

      // Everything is fine we create the order
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let order = {
          order_template_id: this.contractTypes.indexOf(this.contractType) +1, // default order
          position_id: (this.selectedPositions as number),
          started_at: periodsBody[0].started_at,
          finished_at: periodsBody[0].finished_at,
          quantity: this.quantity,
          force_available_workers: this.forceAvailableWorkers,
          force_all_periods: this.forceAllPeriods,
          free_text: this.freeText
        } as Order
        let id = null as null|number
        // API post order always create 1 order period with order started_at and finished_at property
        await axios
          .post(`/v1/orders`, order)
          .then(async (response: AxiosResponse) => {
            EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.order_successfully_created')})
            id = response.data
            if (this.periodList.length > 1) {
              // remove first element (already create into order creation process into API)
              periodsBody.shift()
              await axios
              .post(`/v1/orders/${id}/periods`, periodsBody)
              .then(async (response: AxiosResponse) => {
                EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.order_successfully_created')})
                this.periodList = []
              })
              .catch((error: AxiosError) => {
                EventBus.$emit('snackbar',{axiosError: error.response})
              })
            }
            await this.$router.push(`/orders/${id}`)
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error.response})
          })
      }
    },
    /**
     * Add a new period to periodList
     */
    addPeriod()
    {
      let period = 
      {
        id: this.periodList.length,
        started_at: {dateObject: new Date(), dateString: "", timeString: ""},
        finished_at: {dateObject: new Date(), dateString: "", timeString: ""}
      } as Period

      if (this.periodList.length > 0) {        
        // We get the HH:mm:ss from the startedAt previous period so that they can be used again later
        const nowStartedAtHours = new Date(this.periodList[this.periodList.length - 1].started_at.dateObject as Date)

        // We set the startedAt date of the new period one day after the finishedAt date of the previous period
        // ONLY if startedAt HH:mm:ss are lower than finishedAt HH:mm:ss
        //   for instance 2024-09-06 09:00:00 got its time lower than 2024-09-06 18:00:00, it will generate new period to 2024-09-07 09:00:00 and 2024-09-07 18:00:00
        //   on the other case like 2024-09-06 22:00:00 and 2024-09-07 06:00:00, it will generate new period to 2024-09-07 22:00:00 and 2024-09-08 06:00:00
        const nowStartedAtDate = new Date(this.periodList[this.periodList.length - 1].finished_at.dateObject as Date)
        if (this.periodList[this.periodList.length - 1].started_at.timeString <= this.periodList[this.periodList.length - 1].finished_at.timeString) {
          nowStartedAtDate.setDate(nowStartedAtDate.getDate() + 1)
        }

        // We count the time difference between the startedAt and finishedAt dates of the previous period
        let lastPeriodStartedAtDate = this.periodList[this.periodList.length - 1].started_at.dateObject?.getTime() ?? 0
        let lastPeriodFinishedAtDate = this.periodList[this.periodList.length - 1].finished_at.dateObject?.getTime() ?? 0
        let timeDifference = lastPeriodFinishedAtDate - lastPeriodStartedAtDate;

        // We define the HH:mm:ss for the startedAt date of the new period so that it corresponds to the same HH:mm:ss as the previous period
        nowStartedAtDate.setHours(nowStartedAtHours.getHours(), nowStartedAtHours.getMinutes(), nowStartedAtHours.getSeconds())
        period.started_at.dateObject = nowStartedAtDate
        // We set the finishedAt date by adding the time difference previously found
        const nowFinishedAt = new Date(nowStartedAtDate.getTime() + timeDifference)
        period.finished_at.dateObject = nowFinishedAt
      } else {
        const now = new Date(period.started_at.dateObject as Date)
        now.setHours(now.getHours() + 8)
        period.finished_at.dateObject = now
        period.finished_at.dateString = format(now, "yyyy-MM-dd")
        period.finished_at.timeString = format(now, "HH:mm:ss").slice(0, 5)
      }

      // We increment the period list inputs
      this.periodList.push(
        period
      )
    },
    /**
     * Remove a period from periodList
     * @param index
     */
    removePeriod(index: number)
    {
      this.periodList.splice(index, 1)
    },
    /**
     * Detect errors between the starting and ending date of periods
     * @param periods array of Period objects
     * @returns list of the number of periods in error
     */
    checkChronology(periods: {['started_at']:string, ['finished_at']: string}[]): number[]
    {
        let errorIds = []
        for (let i = 0; i < periods.length; i++) {
          // If finished_at is set before starting_at
          if(new Date(periods[i].started_at) > new Date(periods[i].finished_at)) {
            errorIds.push(i+1)
          }
        }
      return errorIds
    }
  }
})
