
import Vue, { VueConstructor } from "vue"
import axios, { AxiosResponse, AxiosError } from "axios"
import { EventBus } from "@/plugins/eventBus"
import dateFormatMixin from "@/mixins/DateFormatMixin.vue"

interface Status {
    "weight": number,
    "id": number,
    "title": string
}

interface Worker {
    "id": number,
    "last_name": string,
    "first_name": string,
    "profile_picture": string,
    "title": string,
    "order_workers": OrderWorker[]
}

interface OrderWorker {
    "period_id": number,
    "created_at": string,
    "updated_at": string|null,
    "status": number,
    "arrived_at": string|null,
    "contract_done": number,
    "period"?: Period
}

interface Period {
    "id": number,
    "started_at": string,
    "finished_at": string,
    "formated_start_date": string,
    "formated_end_date": string,
    "number": number
}

type UpdateValueType = string | null | boolean

export default (Vue as VueConstructor<Vue & InstanceType<typeof dateFormatMixin>>).extend({
    name: "ValidatedWorkerList",
    mixins: [
        dateFormatMixin
    ],
    props: {
        id: {
            type: Number,
            default: null
        },
        reload: {
            type: Number,
            default: null
        },
        fromClosing: {
            type: Boolean,
            default: false
        },
        fromContract: {
            type: Boolean,
            default: false
        },
        filterPeriodIds: {
            type: Array,
            default: () => [] as number[]
        },
        isClosed: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            workers: [] as Worker[],
            periods: [] as Period[],
            validatedStatus: [] as Status[],
            contract_type: null as string|null,
            workerContractsDone: [] as boolean[]
        }
    },
    watch: {
        reload: function() {
            this.getStatus()
        }
    },
    mounted() {
        this.getStatus()
    },
    methods: {
        updateContract(workerId: number, index: number) {
            this.workers.forEach((worker:Worker)=> {
                if (worker.id === workerId) {
                    worker.order_workers.forEach((ow: OrderWorker) => {
                        this.updateOrderWorkerStatus(workerId, ow.period_id, ow.status, "contract_done", !this.workerContractsDone[index] , null)
                    })
                }
            })
        },
        async getStatus(): Promise<void> {
            // We clear the datas before fetching the new ones
            this.workers = []
            this.validatedStatus = []
            
            // Get the order's template status with weight = 1
            let orderError = false
            await axios.get('/v1/orders/' + this.id)
            .then((response: AxiosResponse) => {
                    this.contract_type = response.data.template.contract_type;
                    (response.data.template.status as Array<Status>).forEach(status  => {
                        if (status.weight === 1) {
                            this.validatedStatus.push(status)
                        }
                    })
                }
            )
            .catch((e: AxiosError) => {
                orderError = true
                EventBus.$emit('snackbar',{axiosError: e})
            })
            // If we didn't get the statuses, we can't get the corresponding workers
            if (orderError) {
                return
            }

            // For each status with weight = 1, we get the corresponding workers
            for (const status of this.validatedStatus) {
                await this.getWorkers(status)
            }

            // For each of the fetched workers, we get their order_workers periods
            await this.getPeriods()

            if (this.fromClosing || this.fromContract) {
                this.$emit('orderWorkerIds', this.workers)
            }

            this.showPeriods()
        },
        showPeriods(): void {
            let testContract = []
            for (const worker of this.workers) {
                // We set a count for the period's number
                for (const orderWorker of worker.order_workers) {
                    testContract.push(Boolean(orderWorker.contract_done).valueOf())
                    let period = this.periods.find(p => p.id === orderWorker.period_id) as Period
                    if (period && period.id) {
                        period.formated_start_date = this.localizeDate(period.started_at, true, true).slice(0, -3)
                        period.formated_end_date = this.localizeDate(period.finished_at, true, true).slice(0, -3)
                        // We use Vue.set for deep reactivity
                        this.setterPeriod(period).forEach((p: Period) => {
                            Vue.set(orderWorker,"period",p)
                        })
                    }
                }
                this.workerContractsDone[this.workers.indexOf(worker)] = !testContract.includes(false)
            }
        },
        setterPeriod(period: Period): Period[] {
            let periodsToSet: Period[] = []
            if (this.fromClosing ? new Date(period.started_at) < new Date() : true) {
                if (this.filterPeriodIds.length > 0) {
                    this.filterPeriodIds.forEach((periodId) => {
                        if (periodId === period.id) {
                            periodsToSet.push(period)
                        }
                    });
                } else {
                    periodsToSet.push(period)
                }
            }
            return periodsToSet
        },
        async getWorkers(status: Status, page = 1): Promise<void> {
            await axios.get(`/v1/workers?page=${page}&fields=first_name,last_name,profile_picture&order=true:${this.id}:${status.id}`)
            .then((response: AxiosResponse) => {
                let result = response.data.map((worker: Worker) => {
                    return {...worker, title: status.title}
                })
                let workersId: number[] = [];
                this.workers.forEach((worker: Worker) => {
                    workersId.push(worker.id)
                });
                this.workers = this.workers.concat(result.map((worker: Worker)=> {
                    if (!workersId.includes(worker.id)) {
                        return worker
                    }
                }))
                this.workers = this.workers.filter((w: Worker)=> w)
                let nb = /-(\d+)\//.exec(response.headers["content-range"])
                let total = /\/(\d+)/.exec(response.headers["content-range"])

                if (nb && total && (Number(nb[1]) < Number(total[1]))) {
                    this.getWorkers(status, page + 1)
                }
            })
            .catch((e: AxiosError) => {
                EventBus.$emit('snackbar',{axiosError: e})
            })
        },
        async getPeriods(): Promise<void> {
            await axios.get(`/v1/orders/${this.id}/periods`)
            .then((response: AxiosResponse) => {
                for (let i = 0; i < response.data.length; i++) {
                    this.periods.push({...response.data[i], number: i+1})
                    
                }
            })
            .catch((e: AxiosError) => {
                EventBus.$emit('snackbar',{axiosError: e})
            })
        },
        updateOrderWorkerStatus(workerId: number, periodId: number, statusId: number, action: string, currentValue: UpdateValueType, startedAt: string|null = null)
        {
            let bodyValue = {} as {[key: string]: null|string|boolean}
            if (action === "arrived_at" && !currentValue) {
                bodyValue[action] = startedAt
            } else if (action === "arrived_at" && currentValue) {
                bodyValue[action] = null
            }
            if (action === "contract_done") {
                bodyValue[action] = !currentValue
            }
            axios.put(`/v1/orders/${this.id}/workers`, {
                [workerId]: {
                    "status": statusId,
                    "periods": [periodId],
                    [action]: bodyValue[action] as null|boolean|string
                }
            })
            .then(() => {
                EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.worker_successfully_updated') });
                this.getStatus()
                if (this.fromContract) {
                    this.$emit('contractDone')
                }
            })
            .catch((e: AxiosError) => {
                EventBus.$emit('snackbar',{axiosError: e})
            })
        }
    }
})
