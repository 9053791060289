

import Vue from "vue"
import axios from "axios"
import { EventBus } from "@/plugins/eventBus"
import ValidatedWorkerList from "../ValidatedWorkerList.vue"
import { format } from "date-fns"
import dateFormatMixin from '@/mixins/DateFormatMixin.vue'
import { VueConstructor } from "vue/types/umd"


interface ItemSelect {
    value: string,
    text: string
}

interface OrderData {
    id: number,
    quantity: number,
    total_arrived: number,
    periods: PeriodData[]
}

interface PeriodData {
    id: number,
    started_at: string,
    finished_at: string
}

interface WorkerData {
    id: number,
    order_workers: OrderWorkerData[]
}

interface OrderWorkerData {
    period_id: number,
    status: number,
    arrived_at: string|null
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof dateFormatMixin>>).extend({
    name: 'CloseOrderWorkerModal',
    components: {
      ValidatedWorkerList
    },
    props: {
        order: {
            type: Object,
        },
        showCloseModal: {
            type: Boolean,
            default: false
        },
        orderPeriods: {
            type: Array as ()=> PeriodData[]
        }
    },
    mixins: [
      dateFormatMixin
    ],
    data () {
        return {
            closeOrderStateItems: [] as ItemSelect[],
            selectedCloseOrderState: null as string|null,
            confirmationCloseModal: false,
            closingReasons: null as string|null,
            showInputClosingReasons: false,
            showModal: false,
            reloadOrderWorkerPeriod: 0,
            filterValue: [] as number[],
            workers: [] as WorkerData[],
        }
    },
    mounted() {
        this.showModal = this.showCloseModal
        this.closeOrderStateItems = []
        if ((this.order as OrderData).periods && this.order.periods.length > 0) {
            if (this.order.total_arrived === (this.order.quantity * this.order.periods?.length)) {
                this.selectedCloseOrderState = "honored"
                this.closeOrderStateItems.push({value: "honored", text: this.$vuetify.lang.t("$vuetify.honored")})
            }
            if (this.order.total_arrived === 0) {
                this.selectedCloseOrderState = "not_honored"
                this.closeOrderStateItems.push({value: "not_honored", text: this.$vuetify.lang.t("$vuetify.not_honored")})
            }
            if (this.order.total_arrived > 0 && this.order.total_arrived < (this.order.quantity * this.order.periods?.length)) {
                this.selectedCloseOrderState = "partially_honored"
                this.closeOrderStateItems.push({value: "partially_honored", text: this.$vuetify.lang.t("$vuetify.partially_honored")})
            }
        }
    },
    computed: {
        selectAllPeriodsIcon(): string {
            if (this.filterValue.length == 0) {
                return 'mdi-checkbox-blank-outline'
            } else if (this.filterValue.length == this.order.periods.length) {
                return 'mdi-close-box'
            } else {
                return 'mdi-minus-box'
            }
        },
    },
    watch:{
        showCloseModal() {
            this.showModal = this.showCloseModal
        },
        selectedCloseOrderState() {
            if (['not_honored', "partially_honored"].includes(this.selectedCloseOrderState as string)) {
                this.showInputClosingReasons = true;
            } 
            if (this.selectedCloseOrderState === 'honored') {
                this.showInputClosingReasons = false;
            }
        },
        filterValue() {
            if (this.$refs.arrived_at) {
                (this.$refs.arrived_at as InstanceType<typeof ValidatedWorkerList>).getStatus()
            }
        },
        workers() {
            this.checkGlobalOrderData()
        }
    },
    methods: {
        hintBuilder() {
            if (this.closingReasons === null || this.closingReasons.length === 0) {
                return this.$vuetify.lang.t('$vuetify.required')
            }
        },
        filterPeriods(periods: PeriodData[]) {
            return periods.map((p: PeriodData)=> {
                if (new Date(p.started_at) < new Date()) {
                    return p
                }
            })
        },
        getOrderWorkers(workers: WorkerData[]) {
            this.workers = workers
        },
        checkGlobalOrderData() {
            let count = 0
            this.workers.forEach((worker: WorkerData) => {
                worker.order_workers.forEach((ow: OrderWorkerData)=> {
                    if (ow.arrived_at !== null) {
                        count++
                    }
                })
            })
            if ((this.order.periods.length * this.order.quantity) === count) {
                this.selectedCloseOrderState = "honored"
                this.closeOrderStateItems = [{value: "honored", text: this.$vuetify.lang.t("$vuetify.honored")}]
            }
            if (count === 0) {
                this.selectedCloseOrderState = "not_honored"
                this.closeOrderStateItems = [{value: "not_honored", text: this.$vuetify.lang.t("$vuetify.not_honored")}]
            }
        },
        updateAllOrderWorker() {
            if (this.filterValue.length === 0) {
                // set all periods into filter to update
                this.order.periods.forEach((p:PeriodData)=> {
                    if (new Date(p.started_at) < new Date()) this.filterValue.push(p.id)
                })
            }
            this.workers.forEach((worker: WorkerData) => {
                worker.order_workers.forEach((ow: OrderWorkerData) => {
                    if (this.filterValue.includes(ow.period_id) && ow.arrived_at === null) {
                        (this.$refs.arrived_at as InstanceType<typeof ValidatedWorkerList>).updateOrderWorkerStatus(
                            worker.id,
                            ow.period_id,
                            ow.status,
                            "arrived_at",
                            null,
                            format(new Date(), 'yyyy-MM-dd HH:mm:ss')
                        )
                    }
                })
            })
        },
        selectAllPeriods() {
            if (this.filterValue.length == this.filterPeriods(this.order.periods).length) {
                this.filterValue = []
            } else {
                this.filterValue = this.filterPeriods(this.order.periods).map((period?: PeriodData)=> {
                    return period?.id
                }) as number[]
            }
        },
        labelSelectPeriod(period: PeriodData) {
            return  `${this.$vuetify.lang.t('$vuetify.from')} ${this.localizeDate(period.started_at, true, true)}
                    ${this.$vuetify.lang.t('$vuetify.to')} ${this.localizeDate(period.finished_at, true, true)}`
        },
        openConfirmation() {
            let testDisabled = true
            if (this.selectedCloseOrderState !== "honored") {
                // if != honored => require data for closing reasons
                testDisabled = !(this.closingReasons === null || this.closingReasons.length === 0)
            }
            this.confirmationCloseModal = (this.selectedCloseOrderState !== null && testDisabled)
        },
        resetCloseOrder(){
            this.selectedCloseOrderState = null
            this.confirmationCloseModal = false
            this.closingReasons = null
            this.showModal = false
            this.$emit('close')
        },
        closeOrderProcess() {
            // building body to update order
            let body = {} as {state: string, status: string, closing_reasons?: string}
            body.status = "closed"
            body.state = this.selectedCloseOrderState as string
            if (["not_honored", "partially_honored"].includes(this.selectedCloseOrderState as string)) {
                body.closing_reasons = this.closingReasons as string
            }
            // update order
            let message =  this.$vuetify.lang.t('$vuetify.closing_success');
            let color = "success";
            axios.put(`/v1/orders`, {[this.order.id]: body})
            .then(()=> {
                this.$emit('update')
            }).catch(() => {
                message = this.$vuetify.lang.t('$vuetify.closing_error')
                color = 'error'
            }).finally(()=>{
                this.resetCloseOrder()
                EventBus.$emit('snackbar', { message, color})
            })
        },
    }
})
