
import Vue from "vue"


interface Period {
  id: number,
  started_at: string,
  finished_at: string
}

interface OrderTemplateStatus {
  id: number,
  step: number,
  title: string,
  weight: number|null,
}

export default Vue.extend({
  name: 'OrderWorkerModal',
  props: {
    worker: {
      type: Object,
      required: true
    },
    order: {
      type: Object
    },
    orderPeriods: {
      type: Array as ()=> Period[]
    }
  },
  data () {
    return {
      statusWeightSelectedNotNull: false, // show period if order multi-date
      statusSelected: {} as OrderTemplateStatus, // selected order template status
      selectedOrderPeriods: [] as Array<number>, // selected order_period(s)
    }
  },
  watch:{
    statusSelected(){
      this.statusWeightSelectedNotNull = this.statusSelected.weight !== null
      this.$emit('statusSelected', {[this.worker.id]: this.statusSelected.id})
      if (this.statusWeightSelectedNotNull) {
        this.selectedOrderPeriods = this.orderPeriods.map((period: Period)=> {
          return period.id
        })
      } else {
        this.selectedOrderPeriods = []
      }
    },
    selectedOrderPeriods() {
      this.$emit('periodSelected', {[this.worker.id]: this.selectedOrderPeriods})
    }
  },
  methods: {
    /**
     * Generate label used as title on order period selection and error messages
     */
    getOrderPeriodTitle(period: Period) {
      // retrieve order period index
      let index = this.orderPeriods.findIndex((eachPeriod: Period) => eachPeriod.id == period.id) + 1 // index start from 0
      // generate dates
      let started_at = new Date(period.started_at)
      let finished_at = new Date(period.finished_at)
      if (this.$vuetify.lang.current === 'fr') {
        return `P${index} du ${('0' + started_at.getDate()).slice(-2)}/${('0'+(started_at.getMonth()+1)).slice(-2)} au ${('0'+finished_at.getDate()).slice(-2)}/${('0'+(finished_at.getMonth()+1)).slice(-2)} de ${('0'+started_at.getHours()).slice(-2)}h${('0'+started_at.getMinutes()).slice(-2)} à ${('0'+finished_at.getHours()).slice(-2)}h${('0'+finished_at.getMinutes()).slice(-2)}`
      } else {
        return `P${index}: ${('0' + (started_at.getMonth()+1)).slice(-2)}/${('0' + started_at.getDate()).slice(-2)} to ${('0' + (finished_at.getMonth()+1)).slice(-2)}/${('0'+finished_at.getDate()).slice(-2)} from ${('0'+started_at.getHours()).slice(-2)}h${('0'+started_at.getMinutes()).slice(-2)} to ${('0'+finished_at.getHours()).slice(-2)}h${('0'+finished_at.getMinutes()).slice(-2)}`
      }
    },
    /**
     * method to select all period
     */
    selectAllPeriods() {
      if (this.selectedOrderPeriods.length == this.orderPeriods.length) {
        this.selectedOrderPeriods = []
      } else {
        this.selectedOrderPeriods = this.orderPeriods.map((period: Period)=> {
          return period.id
        })
      }
    },
    /**
     * Return icon used on select all periods v-select
     */
     selectAllPeriodsIcon(): string {
      if (this.selectedOrderPeriods.length == 0) {
        return 'mdi-checkbox-blank-outline'
      } else if (this.selectedOrderPeriods.length == this.orderPeriods.length) {
        return 'mdi-close-box'
      } else {
        return 'mdi-minus-box'
      }
    }
  }
})
