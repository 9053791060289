

import Vue from "vue"
import ValidatedWorkerList from "../ValidatedWorkerList.vue"
import dateFormatMixin from '@/mixins/DateFormatMixin.vue'
import { VueConstructor } from "vue/types/umd"

interface PeriodData {
    id: number,
    started_at: string,
    finished_at: string
}

interface WorkerData {
    id: number,
    order_workers: OrderWorkerData[]
}

interface OrderWorkerData {
    period_id: number,
    status: number,
    contract_done: number
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof dateFormatMixin>>).extend({
    name: 'ContractDoneOrderWorkerModal',
    components: {
      ValidatedWorkerList
    },
    props: {
        order: {
            type: Object,
        },
        orderPeriods: {
            type: Array as ()=> PeriodData[]
        },
        showModal: {
            type: Boolean,
            default: false
        }
    },
    mixins: [
      dateFormatMixin
    ],
    data () {
        return {
            selectedCloseOrderState: null as string|null,
            workers: [] as WorkerData[],
            showContractDoneModal: false
        }
    },
    mounted() {
        this.showContractDoneModal = this.showModal
    },
    methods: {
        checkDisabledContractButton() {
            let testContract: boolean[] = []
            if (this.workers.length > 0) {
                this.workers.forEach((worker: WorkerData) => {
                    worker.order_workers.forEach((ow: OrderWorkerData) => {
                    testContract.push(Boolean(ow.contract_done).valueOf())
                    });
                });
            }
            return !testContract.includes(false)
        },
        getOrderWorkers(workers: WorkerData[]) {
            this.workers = workers
        },
        updateAllOrderWorker() {
            this.workers.forEach((worker: WorkerData) => {
                worker.order_workers.forEach((ow: OrderWorkerData) => {
                    if (!Boolean(ow.contract_done).valueOf()) {
                        (this.$refs.contract_done as InstanceType<typeof ValidatedWorkerList>).updateOrderWorkerStatus(
                            worker.id,
                            ow.period_id,
                            ow.status,
                            "contract_done",
                            false,
                            null
                        )
                        this.$emit('contractDone')
                    }
                })
            })
            this.reload++
        },
        resetCloseOrder(){
            this.$emit('close')
        }
    }
})
